import { useEffect, useRef, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@mui/material'

// third-party

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { Transitions } from 'components/ui-component/extended/Transitions'
import { useAuth } from 'hooks/useAuth'

// assets
import { IconLogout, IconCopy, IconCircleCheck, IconSettings } from '@tabler/icons-react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FormattedMessage } from 'components/FormattedMessage'

// ==============================|| PROFILE MENU ||============================== //

export const ProfileSection = () => {
  const theme = useTheme()
  const { logout, session } = useAuth()
  const [open, setOpen] = useState(false)
  const [copiedProfileId, setCopiedProfileId] = useState(false)

  const onCopyClick = () => {
    setCopiedProfileId(true)
    setTimeout(() => setCopiedProfileId(false), 2000)
  }

  const userInitials =
    session?.name
      ?.split(' ')
      .map((n) => n[0])
      .join('') || ''

  const userFirstName = session?.name?.split(' ')[0]

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null)

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.blue[50],
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.blue[50],
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.blue[200],
            background: `${theme.palette.blue[200]} !important`,
            color: theme.palette.blue[800],
            '& svg': {
              stroke: theme.palette.blue[800],
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 6px 8px 8px !important',
              cursor: 'pointer',
              color: theme.palette.common.white,
              background: theme.palette.blue[800],
              width: '32px',
              height: '32px',
              '&:hover': {
                color: theme.palette.common.white,
                background: theme.palette.blue[800],
              },
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            alt="user images"
            color={theme.palette.common.white}
          >
            {userInitials}
          </Avatar>
        }
        label={<IconSettings stroke={1.5} size="24px" color={theme.palette.blue[500]} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                    <Box sx={{ pt: '24px', pb: '24px' }}>
                      <Typography variant="h4" sx={{ paddingLeft: '24px' }}>
                        <FormattedMessage id="common.welcome" />, {userFirstName}
                      </Typography>
                      <Typography variant="h4" sx={{ paddingLeft: '24px' }}>
                        {session?.email}
                      </Typography>
                      <Divider sx={{ marginLeft: '14px', marginTop: '16px', marginBottom: '16px' }} />
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '12px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%',
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5,
                          },
                          paddingTop: '2px',
                        }}
                      >
                        {session?.id && (
                          <CopyToClipboard text={session?.id}>
                            <ListItemButton
                              sx={{
                                padding: '0 0 0 24px',
                                '&:hover': {
                                  color: theme.palette.grey[700],
                                  background: theme.palette.blue[50],
                                },
                              }}
                              onClick={onCopyClick}
                            >
                              <ListItemIcon>
                                {copiedProfileId ? (
                                  <IconCircleCheck stroke={1.5} size="20px" color={theme.palette.success.dark} />
                                ) : (
                                  <IconCopy stroke={1.5} size="20px" color={theme.palette.grey[700]} />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                sx={{
                                  minHeight: '48px',
                                  margin: '0',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                primary={
                                  <Typography
                                    variant="bodymd"
                                    sx={copiedProfileId ? { color: theme.palette.success.dark } : { color: 'inherit' }}
                                  >
                                    {copiedProfileId ? (
                                      <FormattedMessage id="common.copied" />
                                    ) : (
                                      <FormattedMessage id="common.copyYourProfileId" />
                                    )}
                                    {!copiedProfileId && <div>{session?.id}</div>}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </CopyToClipboard>
                        )}
                        <ListItemButton
                          sx={{
                            padding: '0 0 0 24px',
                            minHeight: '48px',
                            '&:hover': {
                              color: theme.palette.grey[700],
                              background: theme.palette.blue[50],
                            },
                          }}
                          onClick={logout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="20px" color={theme.palette.grey[700]} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="bodymd" sx={{ color: 'inherit' }}>
                                <FormattedMessage id="common.logout" />
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}
