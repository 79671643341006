import { Dispatch, SetStateAction } from 'react'
import { Button, Typography, css, styled, useMediaQuery, useTheme } from '@mui/material'
import { MainCard } from 'components/ui-component/cards/MainCard'
import { MultiResult } from './MultiResult'
import { RotatingIcon } from './RotatingIcon'
import { Result } from './Result'
import { FEEDBACK_ELEMENTS } from 'views/TextToText/useTextGenerator'
import { FormattedMessage } from 'components/FormattedMessage'
import { useTranslation } from 'react-i18next'
import { ArticlePublications, Prompt } from 'shared'
import { getLengthValueForPublication } from 'utils/helpers'

type ResultConfig<T> = {
  data: T
  isLoading: boolean
  onRegenerate?: () => void
  selected: number
  setSelected: Dispatch<SetStateAction<number>>
}

type SingleResultConfig<T> = Omit<ResultConfig<T>, 'selected' | 'setSelected'>

type Props = {
  publication: ArticlePublications
  metaTitle: ResultConfig<string[]>
  ogTitle: ResultConfig<string[]>
  summary?: SingleResultConfig<string[]>
  teaserHeadline: ResultConfig<string[]>
  teaserTitle: ResultConfig<string[]>
  lead: ResultConfig<string[]>
  setRateModalConfig: Dispatch<
    SetStateAction<{
      title: string
      defaultValue: string
    } | null>
  >
  rewrittenText?: SingleResultConfig<string>
  onRegenerateAll: () => void
  isRegenerateAllLoading: boolean
  feedbackSent: { [key: string]: boolean }
}

export const Results = ({
  publication,
  metaTitle,
  ogTitle,
  teaserHeadline,
  teaserTitle,
  lead,
  rewrittenText,
  summary,
  setRateModalConfig,
  onRegenerateAll,
  isRegenerateAllLoading,
  feedbackSent,
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <MainCard
      title={
        <TitleWrapper $isMobile={matchDownMd}>
          <Typography variant="h3">
            <FormattedMessage id="textToTextResult.resultHeadline" />
          </Typography>
          <Button
            variant="text"
            sx={{
              textTransform: 'uppercase',
              color: theme.palette.blue[500],
              fontSize: '12px !important',
              lineHeight: '20px',
            }}
            startIcon={
              <RotatingIcon $enabled={isRegenerateAllLoading} className="material-symbols-outlined">
                autorenew
              </RotatingIcon>
            }
            disabled={isRegenerateAllLoading}
            onClick={onRegenerateAll}
          >
            <FormattedMessage id="common.regenerateAll" />
          </Button>
        </TitleWrapper>
      }
      sx={{ borderRadius: '12px 12px 0 0' }}
    >
      {metaTitle.data && (
        <MultiResult
          title={
            <>
              <FormattedMessage id={'common.chooseA'} />
              &nbsp;
              <FormattedMessage id={'common.metaTitle'} />
            </>
          }
          content={metaTitle.data}
          onRatingClick={setRateModalConfig}
          onRegenerateClick={metaTitle.onRegenerate}
          isLoading={metaTitle.isLoading}
          feedbackSent={feedbackSent[FEEDBACK_ELEMENTS.metaTitle]}
          feedbackKey={FEEDBACK_ELEMENTS.metaTitle}
          chipLabel={t('common.maxCharacters', {
            value: getLengthValueForPublication(publication, Prompt.META_TITLE) as number,
          })}
          selectedIndex={metaTitle.selected}
          onSelect={(idx: number) => metaTitle.setSelected(idx)}
        />
      )}
      {ogTitle.data && (
        <MultiResult
          title={
            <>
              <FormattedMessage id={'common.chooseA'} />
              &nbsp;
              <FormattedMessage id={'common.ogTitle'} />
            </>
          }
          content={ogTitle.data}
          onRatingClick={setRateModalConfig}
          onRegenerateClick={ogTitle.onRegenerate}
          isLoading={ogTitle.isLoading}
          feedbackSent={feedbackSent[FEEDBACK_ELEMENTS.ogTitle]}
          feedbackKey={FEEDBACK_ELEMENTS.ogTitle}
          chipLabel={t('common.maxCharacters', {
            value: getLengthValueForPublication(publication, Prompt.OG_TITLE) as number,
          })}
          selectedIndex={ogTitle.selected}
          onSelect={(idx: number) => ogTitle.setSelected(idx)}
        />
      )}
      {teaserHeadline.data && (
        <MultiResult
          title={
            <>
              <FormattedMessage id={'common.chooseA'} />
              &nbsp;
              <FormattedMessage id={'common.subHeadline'} />
            </>
          }
          content={teaserHeadline.data}
          onRatingClick={setRateModalConfig}
          onRegenerateClick={teaserHeadline.onRegenerate}
          isLoading={teaserHeadline.isLoading}
          feedbackSent={feedbackSent[FEEDBACK_ELEMENTS.teaserHeadline]}
          feedbackKey={FEEDBACK_ELEMENTS.teaserHeadline}
          chipLabel={t('common.maxCharacters', {
            value: (getLengthValueForPublication(publication, Prompt.TEASER) as number[])?.[0] ?? 0,
          })}
          selectedIndex={teaserHeadline.selected}
          onSelect={(idx: number) => teaserHeadline.setSelected(idx)}
        />
      )}
      {teaserTitle.data && (
        <MultiResult
          title={
            <>
              <FormattedMessage id={'common.chooseA'} />
              &nbsp;
              <FormattedMessage id={'common.title'} />
            </>
          }
          content={teaserTitle.data}
          onRatingClick={setRateModalConfig}
          onRegenerateClick={teaserTitle.onRegenerate}
          isLoading={teaserTitle.isLoading}
          feedbackSent={feedbackSent[FEEDBACK_ELEMENTS.teaserTitle]}
          feedbackKey={FEEDBACK_ELEMENTS.teaserTitle}
          chipLabel={t('common.maxCharacters', {
            value: (getLengthValueForPublication(publication, Prompt.TEASER) as number[])?.[1] ?? 0,
          })}
          selectedIndex={teaserTitle.selected}
          onSelect={(idx: number) => teaserTitle.setSelected(idx)}
        />
      )}
      {lead.data && (
        <MultiResult
          title={t('common.articleLead')}
          content={lead.data}
          onRatingClick={setRateModalConfig}
          onRegenerateClick={lead.onRegenerate}
          isLoading={lead.isLoading}
          feedbackSent={feedbackSent[FEEDBACK_ELEMENTS.articleLead]}
          feedbackKey={FEEDBACK_ELEMENTS.articleLead}
          chipLabel={t('common.maxCharacters', {
            value: getLengthValueForPublication(publication, Prompt.LEAD) as number,
          })}
          selectedIndex={lead.selected}
          onSelect={(idx: number) => lead.setSelected(idx)}
        />
      )}
      {summary && summary.data && (
        <Result
          title={t('common.summary')}
          content={summary.data.map((point) => `• ${point}`).join('\n')}
          onRatingClick={setRateModalConfig}
          onRegenerateClick={summary.onRegenerate}
          isLoading={summary.isLoading}
          feedbackSent={feedbackSent[FEEDBACK_ELEMENTS.summary]}
        />
      )}
      {rewrittenText && rewrittenText.data && (
        <Result
          title={t('common.mainText')}
          content={rewrittenText.data}
          onRatingClick={setRateModalConfig}
          onRegenerateClick={rewrittenText.onRegenerate}
          isLoading={rewrittenText.isLoading}
          feedbackSent={feedbackSent[FEEDBACK_ELEMENTS.text]}
        />
      )}
    </MainCard>
  )
}

const TitleWrapper = styled('div')<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  ${({ $isMobile }) =>
    $isMobile
      ? css`
          justify-content: space-between;
        `
      : css`
          gap: 16px;
        `}
`
