import React, { useEffect, useState } from 'react'
import { getConversations, getMessages } from 'api/question-viewer'
import { QuestionViewerConversation } from 'shared'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import DayJSTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(DayJSTimezone)

export const QuestionViewer: React.FC = () => {
  const [messages, setMessages] = useState<any[]>([])
  const [selectedDays, setSelectedDays] = useState<number>(7)
  const [conversations, setConversations] = useState<QuestionViewerConversation[]>([])
  const [currentConversation, setCurrentConversation] = useState<string | null>(null)
  const [currentUserId, setCurrentUserId] = useState<string | null>(null)

  const fetchConversations = async (selectedDays: number) => {
    try {
      const conversations = await getConversations(selectedDays)

      if (conversations === null) {
        return
      }

      setConversations(conversations)
      // Clear chat when fetching new conversations
      setMessages([])
      setCurrentConversation(null)
      setCurrentUserId(null)
    } catch (error) {
      console.error('Error fetching conversations:', error)
      setConversations([])
    }
  }

  const fetchMessages = async (userId: string, conversationId: string) => {
    try {
      const messages = await getMessages(userId, conversationId)

      if (messages === null) {
        return
      }

      setMessages(messages)
      setCurrentConversation(conversationId)
      setCurrentUserId(userId)
    } catch (error) {
      console.error('Error fetching messages:', error)
      setMessages([])
    }
  }

  const renderContent = (content: any) => {
    try {
      const jsonContent = typeof content === 'string' ? JSON.parse(content) : content
      if (jsonContent && Array.isArray(jsonContent.content)) {
        const textParts = jsonContent.content
          .map((item: any) =>
            item.category ? `<p><strong>${item.category}:</strong> ${item.text}</p>` : `<p>${item.text}</p>`,
          )
          .join('')
        const sources = Object.values<{ title: string; url: string }>(jsonContent.links)
          .map(
            (link: { title: string; url: string }, index) =>
              `<p>[${index + 1}]: <a href="${link.url}" target="_blank">${link.title} [${link.url}]</a></p>`,
          )
          .join('')

        const traceId = jsonContent.traceId ?? 'unknown'
        return `
        ${textParts}<div class="sources"><strong>Sources:</strong>${sources}</div><div class="trace-id"><strong>Trace ID: </strong>${traceId}</div>`
      }
    } catch (error) {
      console.warn('fallback to text content', error, content)
    }
    return content
  }

  const renderContentHeader = () => {
    if (currentConversation == null || messages.length === 0 || currentUserId == null) {
      return
    }

    const startDate = dayjs.unix(messages[0].sent_at._seconds).tz('Europe/Zurich').format()
    const endDate = dayjs
      .unix(messages[messages.length - 1].sent_at._seconds)
      .tz('Europe/Zurich')
      .format()

    return (
      <div
        key={currentConversation}
        className={'message user'}
        style={{
          marginBottom: '10px',
          padding: '5px',
          borderRadius: '5px',
          backgroundColor: '#e6f3ff',
          textAlign: 'left',
        }}
      >
        <div>
          <b>Conversation id:</b> {currentConversation}
        </div>
        <div>
          <b>User id:</b> {currentUserId}
        </div>
        <div>
          <b>Start date:</b> {startDate}
        </div>
        <div>
          <b>End date:</b> {endDate}
        </div>
      </div>
    )
  }

  useEffect(() => {
    fetchConversations(selectedDays)
  }, [selectedDays])

  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <div id="filter" style={{ padding: '10px', backgroundColor: '#f0f0f0' }}>
        <label htmlFor="days">Show conversations from last:</label>
        <select id="days" value={selectedDays} onChange={(e) => setSelectedDays(Number(e.target.value))}>
          <option value="3">3 days</option>
          <option value="7">7 days</option>
          <option value="14">14 days</option>
          <option value="30">30 days</option>
          <option value="90">90 days</option>
        </select>
      </div>
      <div id="content" style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <div id="sidebar" style={{ width: '200px', overflowY: 'auto', backgroundColor: '#f9f9f9', padding: '10px' }}>
          {conversations.map((conv) => (
            <div
              key={conv.id}
              className="conversation"
              style={{
                cursor: 'pointer',
                padding: '5px',
                marginBottom: '5px',
                backgroundColor: '#e0e0e0',
                fontWeight: conv.id === currentConversation ? 'bold' : 'normal',
              }}
              onClick={() => fetchMessages(conv.user_id, conv.id)}
            >
              {conv.last_message_datetime}
            </div>
          ))}
        </div>
        <div id="chat" style={{ flex: 1, overflowY: 'auto', padding: '10px' }}>
          {renderContentHeader()}
          <div>Conversation:</div>
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`message ${msg.author === 'user' ? 'user' : 'assistant'}`}
              style={{
                marginBottom: '10px',
                padding: '5px',
                borderRadius: '5px',
                backgroundColor: msg.author === 'user' ? '#e6f3ff' : '#f0f0f0',
                textAlign: msg.author === 'user' ? 'right' : 'left',
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: renderContent(msg.content) }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
