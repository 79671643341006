import { AxiosError } from 'axios'
import { config } from 'config'
import { ApiResponse, QuestionViewerConversation, QuestionViewerMessage } from 'shared'
import { axiosInstance } from 'utils/axios'

export const getConversations = async (days = 7) => {
  const url = `${config.apiUrl}/chatbot/question-viewer/conversations?days=${days}`
  try {
    const result = await axiosInstance.get<ApiResponse<QuestionViewerConversation[]>>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })

    return result.data.data
  } catch (e) {
    throw new AxiosError(String(e))
  }
}

export const getMessages = async (userId: string, conversationId: string) => {
  const url = `${config.apiUrl}/chatbot/question-viewer/messages/${userId}/${conversationId}`
  try {
    const result = await axiosInstance.get<ApiResponse<ApiResponse<QuestionViewerMessage>[]>>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })

    return result.data.data
  } catch (e) {
    throw new AxiosError(String(e))
  }
}
