// project imports
import { AuthGuard } from 'utils/route-guard/AuthGuard'
import { MainLayout } from 'layout/MainLayout'
import { DashboardView } from 'views/Dashboard'
import { routes } from 'constants/routes'
import { SpeechToTextListView } from 'views/SpeechToText/SpeechToTextList'
import { AboutView } from 'views/About'
import { ManifestoView } from 'views/Manifesto'
import { LearnMoreView } from 'views/LearnMore'
import { RecommendedView } from 'views/Recommended'
import { SpeechToTextItemView } from 'views/SpeechToText/SpeechToTextItem'
import { AppWrapper } from 'components/AppWrapper'
import { ArticleSummaryGeneratorView } from 'views/ArticleSummaryGenerator/ArticleSummaryGenerator'
import { ChatbotView } from 'views/Chatbot/Chatbot'
import { Outlet } from 'react-router-dom'
import { Article1View } from 'views/TextToSpeech/Article1View'
import { Article2View } from 'views/TextToSpeech/Article2View'
import { TitleGeneratorView } from 'views/TextToText/TitleGenerator'
import { ArticleGeneratorView } from 'views/TextToText/ArticleGenerator'
import { TranslatorView } from 'views/Translator/TranslatorView'
import { TitlePluginView } from 'views/Plugin/TitlePlugin'
import { SummaryPluginView } from 'views/Plugin/SummaryPlugin'
import { ChatbotDevView } from 'views/Chatbot/ChatbotDev'
import { ChatbotAdmin } from 'views/Chatbot/ChatbotAdmin'
import { BlickWidgets } from 'views/BlickWidgets/BlickWidgets'
import { QuestionViewer } from 'views/QuestionViewer/QuestionViewer'
import { PageNotFoundView } from 'views/PageNotFound/PageNotFoundView'

// ==============================|| MAIN ROUTING ||============================== //

export const MainRoutes = {
  path: '/',
  element: (
    <AppWrapper>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </AppWrapper>
  ),
  children: [
    {
      path: '/',
      element: <DashboardView />,
    },
    {
      path: routes.dashboard.url,
      element: <DashboardView />,
    },
    {
      path: routes.speechToText.url,
      element: <SpeechToTextListView />,
    },
    {
      path: `${routes.speechToText.url}/:id`,
      element: <SpeechToTextItemView />,
    },
    {
      path: routes.titleGenerator.url,
      element: <TitleGeneratorView />,
    },
    {
      path: routes.articleGenerator.url,
      element: <ArticleGeneratorView />,
    },
    {
      path: routes.articleSummaryGenerator.url,
      element: <ArticleSummaryGeneratorView />,
    },
    {
      path: routes.translator.url,
      element: <TranslatorView />,
    },
    {
      path: routes.chatbot.url,
      element: <ChatbotView />,
    },
    {
      path: routes.chatbotDev.url,
      element: <ChatbotDevView />,
    },
    {
      path: routes.chatbotAdmin.url,
      element: <ChatbotAdmin />,
    },
    {
      path: routes.blickWidgets.url,
      element: <BlickWidgets />,
    },
    {
      path: routes.about.url,
      element: <AboutView />,
    },
    {
      path: routes.manifesto.url,
      element: <ManifestoView />,
    },
    {
      path: routes.learnMore.url,
      element: <LearnMoreView />,
    },
    {
      path: routes.recommendedTools.url,
      element: <RecommendedView />,
    },
  ],
}

export const PluginRoutes = {
  path: '/',
  element: (
    <AppWrapper>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AppWrapper>
  ),
  children: [
    {
      path: '/plugin/meta-title',
      element: <TitlePluginView type="meta-title" />,
    },
    {
      path: '/plugin/lead',
      element: <TitlePluginView type="lead" />,
    },
    {
      path: '/plugin/teaser-headline',
      element: <TitlePluginView type="teaser-headline" />,
    },
    {
      path: '/plugin/teaser-title',
      element: <TitlePluginView type="teaser-title" />,
    },
    {
      path: '/plugin/og-title',
      element: <TitlePluginView type="og-title" />,
    },
    {
      path: '/plugin/summary',
      element: <SummaryPluginView />,
    },
  ],
}

export const MainRoutesNoLayout = {
  path: '/',
  element: (
    <AppWrapper>
      <Outlet />
    </AppWrapper>
  ),
  children: [
    {
      path: '/text-to-speech/1',
      element: <Article1View />,
    },
    {
      path: '/text-to-speech/2',
      element: <Article2View />,
    },
    {
      path: '/chatbot/question-viewer',
      element: <QuestionViewer />,
    },
    {
      path: '*',
      element: <PageNotFoundView />,
    },
  ],
}
