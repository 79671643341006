'use client'

// material-ui
import { Grid, IconButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
// External solution is used here instead of MUI due to unresolved TextareaAutosize crash: https://github.com/mui/base-ui/issues/167
import TextareaAutosize from 'react-textarea-autosize'

// project imports
import { MainCard } from 'components/ui-component/cards/MainCard'
import { CopyDownloadActions } from './components/CopyDownloadActions'
import { LanguageSelector, DETECT_LANGUAGE_ID, TRANSLATION_LANGUAGES } from './components/LanguageSelector'
import { useEffect, useMemo, useState } from 'react'
import { TranslationLoader } from './components/TranslationLoader'
import { downloadTextTranslation, translateText } from 'api/translator'
import { debounce } from 'lodash-es'
import { Spacer } from 'components/ui-component/Spacer'
import { Rating } from 'components/Rating'
import { RateModal } from 'components/RateModal'
import { ToolboxDownloadOptions } from 'types/common'
import { useTranslation } from 'react-i18next'
import { FormattedMessage } from 'components/FormattedMessage'
import { trackRating, trackTranslateAction } from 'tracking/gtm'
import { useAuth } from 'hooks/useAuth'
import { GoogleAnalyticsContext } from 'shared'
import { useLocation } from 'react-router-dom'

//assets import
import { SwitchIcon } from './icons/SwitchIcon'

export const TranslatorView = () => {
  const { pathname } = useLocation()
  const { session } = useAuth()
  const theme = useTheme()
  const { t } = useTranslation()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const [sourceValue, setSourceValue] = useState('')
  const [resultValue, setResultValue] = useState('')
  const [sourceLanguage, setSourceLanguage] = useState(DETECT_LANGUAGE_ID)
  const [targetLanguage, setTargetLanguage] = useState('FR')
  const [detectedLang, setDetectedLang] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [rateModalConfig, setRateModalConfig] = useState<{ title: string; defaultValue: string } | null>(null)
  const [feedbackSent, setFeedbackSent] = useState(false)
  const onRateModalConfirm = () => {
    setFeedbackSent(true)
  }

  const isDetectedLanguageInLanguageSelector = useMemo(
    () => !!detectedLang && TRANSLATION_LANGUAGES(t).some((lang) => lang.value === detectedLang),
    [detectedLang, t],
  )

  const isSwitchLangButtonDisabled = useMemo(
    () => sourceLanguage === DETECT_LANGUAGE_ID && !isDetectedLanguageInLanguageSelector,
    [sourceLanguage, isDetectedLanguageInLanguageSelector],
  )

  const translate = async ({ sourceValue, sourceLanguage, targetLanguage }: any) => {
    try {
      !sourceValue && setResultValue('')
      if (!sourceValue || sourceLanguage === targetLanguage) {
        return
      }
      setFeedbackSent(false)
      setIsLoading(true)
      const translation = await translateText({
        text: sourceValue,
        sourceLanguage: sourceLanguage === DETECT_LANGUAGE_ID ? undefined : sourceLanguage,
        targetLanguage: targetLanguage === 'EN' ? 'EN-US' : targetLanguage,
      })
      trackTranslateAction({
        page: pathname,
        hashedUserId: session?.hashedId,
        context: GoogleAnalyticsContext.TRANSLATOR,
        sourceLanguage,
        targetLanguage,
      })
      setResultValue(translation.text)
      setDetectedLang(translation['detected_source_language'])
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const debouncedTranslate = useMemo(
    () =>
      debounce((data) => {
        translate(data)
      }, 200),
    // eslint-disable-next-line
    [],
  )

  useEffect(() => {
    setDetectedLang(null)
    debouncedTranslate({ sourceValue, sourceLanguage, targetLanguage })
  }, [sourceValue, sourceLanguage, targetLanguage, debouncedTranslate])

  const onSourceTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value || ''
    setSourceValue(value)
  }

  const onClear = () => {
    setSourceValue('')
    setResultValue('')
  }

  const onResultDownload = async (type: ToolboxDownloadOptions) => {
    const result = await downloadTextTranslation(
      {
        text: sourceValue,
        sourceLanguage: sourceLanguage === DETECT_LANGUAGE_ID ? undefined : sourceLanguage,
        targetLanguage: targetLanguage === 'EN' ? 'EN-US' : targetLanguage,
      },
      type,
    )

    createDownloadableFile(result, type)
  }

  const onSourceDownload = async (type: ToolboxDownloadOptions) => {
    const result = await downloadTextTranslation(
      {
        text: sourceValue,
      },
      type,
    )

    createDownloadableFile(result, type)
  }

  const onSwitchTranslationLanguges = () => {
    const isDetectLang = sourceLanguage === DETECT_LANGUAGE_ID
    if (isDetectLang && !detectedLang && !isDetectedLanguageInLanguageSelector) return

    const oldSourceLang = isDetectLang && detectedLang ? detectedLang : sourceLanguage
    const oldTargetLang = targetLanguage

    setSourceValue(resultValue)
    setSourceLanguage(oldTargetLang)
    setTargetLanguage(oldSourceLang)
  }

  const createDownloadableFile = async (result: Blob | null, type: ToolboxDownloadOptions) => {
    if (!result) {
      return
    }

    const href = URL.createObjectURL(result)
    const link = document.createElement('a')

    link.href = href
    link.download = `translation.${type}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  return (
    <MainCard
      title={t('translator.title')}
      contentSX={{
        padding: {
          md: '20px',
          sm: '20px 0',
          xs: '20px 0',
        },
      }}
    >
      <Grid
        container
        sx={{
          height: '100%',
        }}
      >
        <Grid
          container
          columns={13}
          sx={{
            height: '100%',
            padding: {
              md: '0 20px',
              sm: '0 16px',
              xs: '0 16px',
            },
          }}
        >
          <Grid item md={6} xs={5}>
            <LanguageSelector
              isSource
              onChange={setSourceLanguage}
              detectedLang={detectedLang}
              currentLanguage={sourceLanguage}
            />
          </Grid>
          <Grid
            item
            md={1}
            xs={3}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <IconButton
              onClick={onSwitchTranslationLanguges}
              disabled={isSwitchLangButtonDisabled}
              sx={{
                marginTop: matchDownMd ? '-20px' : '0',
                ...(isSwitchLangButtonDisabled ? { '& svg path': { fill: theme.palette.grey[400] } } : {}),
              }}
            >
              <SwitchIcon />
            </IconButton>
          </Grid>
          <Grid item md={6} xs={5}>
            <LanguageSelector onChange={setTargetLanguage} currentLanguage={targetLanguage} />
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} sx={{ padding: matchDownMd ? '20px 16px' : '20px 40px 20px 0px' }}>
          <CopyDownloadActions text={sourceValue} onDownload={onSourceDownload} isLoading={isLoading} />
          <TextareaWrapper>
            {sourceValue && (
              <ClearButton onClick={onClear} size="large">
                <CloseIcon fontSize="small" />
              </ClearButton>
            )}
            <Textarea
              value={sourceValue}
              onChange={onSourceTextChange}
              minRows={15}
              placeholder={t('translator.source')}
              sx={{ paddingRight: '30px' }}
            />
            <Typography variant="bodysm" sx={{ lineHeight: '24px', marginTop: '10px', color: theme.palette.grey[500] }}>
              {t('translator.limit', { length: sourceValue.length })}
            </Typography>
          </TextareaWrapper>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{ padding: '20px 20px 44px 20px', backgroundColor: theme.palette.grey[50], marginBottom: '-24px' }}
        >
          <CopyDownloadActions text={resultValue} onDownload={onResultDownload} isLoading={isLoading} />
          {isLoading ? (
            <TranslationLoader />
          ) : resultValue ? (
            <>
              <StyledResultTypography variant="bodybg" sx={{ paddingTop: '16px' }}>
                {resultValue}
              </StyledResultTypography>
              <Spacer v size={20} />
              <Rating
                title={t('translator.rate')}
                feedbackKey="translation"
                onRatingClick={setRateModalConfig}
                feedbackSent={feedbackSent}
              />
            </>
          ) : (
            <Typography variant="h3" sx={{ paddingTop: '16px', color: theme.palette.grey[500] }}>
              <FormattedMessage id="translator.result" />
            </Typography>
          )}
        </Grid>
      </Grid>
      {rateModalConfig && (
        <RateModal
          isOpen={Boolean(rateModalConfig)}
          onClose={() => setRateModalConfig(null)}
          config={rateModalConfig}
          onConfirm={onRateModalConfirm}
          onTrack={(rating, feedbackText) =>
            trackRating({
              page: pathname,
              hashedUserId: session?.hashedId,
              elementLabel: rateModalConfig.title,
              context: GoogleAnalyticsContext.TRANSLATOR,
              rating,
              feedbackText,
            })
          }
        />
      )}
    </MainCard>
  )
}

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Lato';
  resize: vertical;

  &::placeholder {
    color: ${({ theme }) => theme.palette.grey[500]};
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
`

const TextareaWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const ClearButton = styled(IconButton)`
  margin-bottom: -44px;
`

const StyledResultTypography = styled(Typography)`
  white-space: pre-wrap;
`
