'use client'

import { Suspense, useState } from 'react'

// third-party

// project-import
import { Loader } from 'components/ui-component/Loader'
import { Snackbar } from 'components/ui-component/extended/Snackbar'

import { ThemeCustomization } from 'themes'
import { NavigationScroll } from 'layout/NavigationScroll'

import { AuthProvider } from 'contexts/AuthContext'
import { AppProvider } from './AppContext'
import 'utils/date/initDates'
import { TranscriptionProvider } from './TranscriptionContext'
import { RouterProvider } from 'react-router-dom'
import { router } from 'routes'
import { TranslationProvider } from './TranslationContext'
import { BrowserExtensionProvider } from './BrowserExtensionContext'

export function ProviderWrapper() {
  const [loading] = useState<boolean>(true)

  if (!loading) return <Loader />

  return (
    <AppProvider>
      <ThemeCustomization>
        <Suspense fallback="loading">
          <BrowserExtensionProvider>
            <TranslationProvider>
              <NavigationScroll>
                <AuthProvider>
                  <TranscriptionProvider>
                    <RouterProvider router={router} />
                    <Snackbar />
                  </TranscriptionProvider>
                </AuthProvider>
              </NavigationScroll>
            </TranslationProvider>
          </BrowserExtensionProvider>
        </Suspense>
      </ThemeCustomization>
    </AppProvider>
  )
}
