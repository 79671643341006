import { useEffect, useState } from 'react'

// material-ui
import { styled, useTheme } from '@mui/material/styles'
import {
  Box,
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material'

// project imports
import { NavItem } from 'layout/MainLayout/MenuList/NavItem'
import { Transitions } from 'components/ui-component/extended/Transitions'

import { LAYOUT_CONST } from 'constant'

// types
import { NavItemType } from 'types'

// assets
import { IconChevronDown, IconChevronRight, IconChevronUp } from '@tabler/icons-react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useApp } from 'hooks/useApp'
import { MaterialIcon } from 'components/ui-component/MaterialIcon'
import { Spacer } from 'components/ui-component/Spacer'
import { useLocation } from 'react-router-dom'
import { config } from 'config'

// mini-menu - wrapper
const PopperStyledMini = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    backgroundColor: theme.palette.background.paper,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

// horizontal-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 34,
    left: -5,
    width: 12,
    height: 12,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderWidth: '6px',
    borderStyle: 'solid',
    borderColor: `transparent transparent ${theme.palette.background.paper}  ${theme.palette.background.paper}`,
  },
  '&[data-popper-placement="left-start"]:before': {
    left: 'auto',
    right: -5,
    borderColor: `${theme.palette.background.paper}  ${theme.palette.background.paper} transparent transparent`,
  },
  '&[data-popper-placement="left-end"]:before': {
    top: 'auto',
    bottom: 15,
    left: 'auto',
    right: -5,
    borderColor: `${theme.palette.background.paper}  ${theme.palette.background.paper} transparent transparent`,
  },
  '&[data-popper-placement="right-end"]:before': {
    top: 'auto',
    bottom: 15,
  },
}))

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

type VirtualElement = {
  getBoundingClientRect: () => ClientRect | DOMRect
  contextElement?: Element
}

interface NavCollapseProps {
  menu: NavItemType
  level: number
  parentId: string
}

export const NavCollapse = ({ menu, level, parentId }: NavCollapseProps) => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const { layout, borderRadius } = config
  const { drawerOpen } = useApp()

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<string | null | undefined>(null)
  const [anchorEl, setAnchorEl] = useState<VirtualElement | (() => VirtualElement) | null | undefined>(null)

  const handleClickMini = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
  ) => {
    setAnchorEl(null)
    if (drawerOpen) {
      setOpen(!open)
      setSelected(!selected ? menu.id : null)
    } else {
      setAnchorEl(event?.currentTarget)
    }
  }

  const handleHover = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
  ) => {
    setAnchorEl(event?.currentTarget)
  }

  const handleClosePopper = () => {
    setOpen(false)
    setSelected(null)
    setAnchorEl(null)
  }

  const openMini = Boolean(anchorEl)
  const { pathname } = useLocation()

  const checkOpenForParent = (child: NavItemType[], id: string) => {
    child.forEach((item: NavItemType) => {
      if (item.url === pathname) {
        setOpen(true)
        setSelected(id)
      }
    })
  }

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false)
    setSelected(null)
    if (openMini) setAnchorEl(null)
    if (menu.children) {
      menu.children.forEach((item: NavItemType) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id!)
        }
        if (pathname && pathname.includes('product-details')) {
          if (item.url && item.url.includes('product-details')) {
            setSelected(menu.id)
            setOpen(true)
          }
        }
        if (item.url === pathname) {
          setSelected(menu.id)
          setOpen(true)
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children])

  // menu collapse & item
  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} parentId={parentId} />
      case 'item':
        return <NavItem isParents key={item.id} item={item} level={level + 1} parentId={parentId} />
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  const isSelected = selected === menu.id

  const Icon = menu.icon!
  const menuIcon = menu.icon ? (
    <Icon
      strokeWidth={1.5}
      size={drawerOpen ? '20px' : '24px'}
      style={{ color: isSelected ? theme.palette.blue[500] : theme.palette.text.primary }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        color: isSelected ? theme.palette.blue[500] : theme.palette.text.primary,
        width: isSelected ? 8 : 6,
        height: isSelected ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  )

  const collapseIcon = drawerOpen ? (
    <IconChevronUp stroke={1.5} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
  ) : (
    <IconChevronRight stroke={1.5} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
  )

  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary'

  const popperId = openMini ? `collapse-pop-${menu.id}` : undefined

  return (
    <>
      {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
        <>
          <ListItemButton
            sx={{
              zIndex: 1201,
              borderRadius: `${borderRadius}px`,
              mb: 0.5,
              paddingTop: '6px',
              paddingBottom: '6px',
              ...(drawerOpen &&
                level === 1 &&
                theme.palette.mode !== 'dark' && {
                  '&:hover': {
                    background: theme.palette.blue[50],
                  },
                  '&.Mui-selected': {
                    background: theme.palette.blue[50],
                    color: theme.palette.blue[600],
                    '&:hover': {
                      color: theme.palette.blue[600],
                      background: theme.palette.blue[50],
                    },
                  },
                }),
              ...((!drawerOpen || level !== 1) && {
                py: level === 1 ? 0 : 1,
                '&:hover': {
                  bgcolor: 'transparent',
                },
                '&.Mui-selected': {
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                  bgcolor: 'transparent',
                },
              }),
            }}
            selected={isSelected}
            {...(!drawerOpen && { onMouseEnter: handleClickMini, onMouseLeave: handleClosePopper })}
            onClick={handleClickMini}
          >
            {menuIcon && (
              <ListItemIcon
                sx={{
                  minWidth: level === 1 ? 36 : 18,
                  color: isSelected ? theme.palette.blue[600] : textColor,
                  ...(!drawerOpen &&
                    level === 1 && {
                      borderRadius: `${borderRadius}px`,
                      width: 46,
                      height: 46,
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&:hover': {
                        bgcolor: theme.palette.blue[50],
                      },
                      ...(isSelected && {
                        bgcolor: theme.palette.blue[50],
                        '&:hover': {
                          bgcolor: theme.palette.blue[50],
                        },
                      }),
                    }),
                }}
              >
                {typeof menu.icon === 'string' ? (
                  <>
                    <MaterialIcon name={menu.icon} fontSize="20px" />
                    {drawerOpen && <Spacer h size={16} />}
                  </>
                ) : (
                  menuIcon
                )}
              </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
              <ListItemText
                primary={
                  <Typography variant="bodymd" color="inherit" sx={{ my: 'auto' }}>
                    {menu.title}
                  </Typography>
                }
                secondary={
                  menu.caption && (
                    <Typography
                      variant="caption"
                      sx={{ ...theme.typography.subMenuCaption }}
                      display="block"
                      gutterBottom
                    >
                      {menu.caption}
                    </Typography>
                  )
                }
              />
            )}

            {openMini || open ? (
              collapseIcon
            ) : (
              <IconChevronDown stroke={1.5} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
            )}

            {!drawerOpen && (
              <PopperStyledMini
                open={openMini}
                anchorEl={anchorEl}
                placement="right-start"
                style={{
                  zIndex: 2001,
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [-12, 0],
                    },
                  },
                ]}
              >
                {({ TransitionProps }: any) => (
                  <Transitions in={openMini} {...TransitionProps}>
                    <Paper
                      sx={{
                        overflow: 'hidden',
                        mt: 1.5,
                        boxShadow: theme.shadows[8],
                        backgroundImage: 'none',
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClosePopper}>
                        <Box>{menus}</Box>
                      </ClickAwayListener>
                    </Paper>
                  </Transitions>
                )}
              </PopperStyledMini>
            )}
          </ListItemButton>
          {drawerOpen && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              {open && (
                <List component="div" disablePadding>
                  {menus}
                </List>
              )}
            </Collapse>
          )}
        </>
      ) : null}

      {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? (
        <>
          <ListItemButton
            id={`boundary-${popperId}`}
            disableRipple
            selected={isSelected}
            onMouseEnter={handleHover}
            onMouseLeave={handleClosePopper}
            onClick={handleHover}
            aria-describedby={popperId}
          >
            {menuIcon && <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>}
            <ListItemText
              primary={
                <Typography variant="bodymd" color="inherit" sx={{ my: 'auto' }}>
                  {menu.title}
                </Typography>
              }
            />
            {openMini ? <IconChevronRight stroke={1.5} size="16px" /> : <IconChevronDown stroke={1.5} size="16px" />}

            {anchorEl && (
              <PopperStyled
                id={popperId}
                open={openMini}
                anchorEl={anchorEl}
                placement="right-start"
                style={{
                  zIndex: 2001,
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [-10, 0],
                    },
                  },
                ]}
              >
                {({ TransitionProps }: any) => (
                  <Transitions in={openMini} {...TransitionProps}>
                    <Paper
                      sx={{
                        overflow: 'hidden',
                        mt: 1.5,
                        py: 0.5,
                        boxShadow: theme.shadows[8],
                        backgroundImage: 'none',
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClosePopper}>
                        <Box>{menus}</Box>
                      </ClickAwayListener>
                    </Paper>
                  </Transitions>
                )}
              </PopperStyled>
            )}
          </ListItemButton>
        </>
      ) : null}
    </>
  )
}
