import { useTheme } from '@mui/material/styles'
import { Box, Button, Grid, Typography } from '@mui/material'
import { MainCard } from 'components/ui-component/cards/MainCard'
import robotBroken from 'assets/images/robot-broken.svg'
import { FormattedMessage } from 'components/FormattedMessage'
import { routes } from 'constants/routes'
import { useNavigate } from 'react-router-dom'

export const PageNotFoundView = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const onBackToDashboardClick = () => navigate(routes.dashboard.url)

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-end"
      sx={{ minHeight: '100vh', background: theme.palette.blue[50] }}
    >
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
          <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
            <MainCard
              sx={{
                maxWidth: { xs: 410 },
                margin: { xs: 2.5, md: 3 },
              }}
              content={false}
            >
              <Box sx={{ p: '20px' }}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item sx={{ textAlign: 'center' }} xs={12}>
                    <img src={robotBroken} alt="Robot broken" width="130" height="130" />
                  </Grid>
                  <Grid item sx={{ textAlign: 'center', paddingTop: '16px' }} xs={12}>
                    <Typography variant="h3" sx={{ color: theme.palette.blue[500] }}>
                      <FormattedMessage id="pageNotFound.title" />
                    </Typography>
                  </Grid>
                  <Grid item sx={{ textAlign: 'center', paddingTop: '8px' }} xs={12}>
                    <FormattedMessage id="pageNotFound.description" />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: '20px', textAlign: 'center' }}>
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ textTransform: 'uppercase', p: '10px 14px !important' }}
                      onClick={onBackToDashboardClick}
                    >
                      <FormattedMessage id="pageNotFound.button" />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
