import React from 'react'

import { useTheme } from '@mui/material/styles'
import { Button, ClickAwayListener, Divider, Grow, MenuItem, Paper, Popper } from '@mui/material'
import MenuList from '@mui/material/MenuList'
import styled from '@mui/system/styled'
import { ToolboxDownloadOptions } from 'types/common'
import { FormattedMessage } from 'components/FormattedMessage'
import { Job } from 'shared'

type Props = {
  onDelete: () => void
  onDownload: (type: ToolboxDownloadOptions) => void
  job: (Job & { url: string }) | undefined
  translateView: boolean
}

const TOOLBOX_ACTIONS: ({ type: ToolboxDownloadOptions; text: string } | { element: string })[] = [
  {
    type: ToolboxDownloadOptions.TXT,
    text: 'TXT',
  },
  {
    type: ToolboxDownloadOptions.TXT,
    text: 'TXT',
  },
  {
    type: ToolboxDownloadOptions.DOCX,
    text: 'DOCX',
  },
  {
    type: ToolboxDownloadOptions.SRT,
    text: 'SRT',
  },
  {
    type: ToolboxDownloadOptions.VTT,
    text: 'VTT',
  },
  {
    element: 'divider',
  },
]

export const TranscriptionActionsMenu = ({ onDelete, onDownload, job, translateView }: Props) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  return (
    <div>
      <Button
        ref={anchorRef}
        variant="contained"
        disableElevation
        onClick={handleToggle}
        endIcon={<span className="material-symbols-outlined">keyboard_arrow_down</span>}
        sx={{
          textTransform: 'uppercase',
          padding: '12px 16px',
          fontSize: '14px',
          lineHeight: '20px',
          background: theme.palette.blue[500],
        }}
      >
        <FormattedMessage id="common.actions" />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: '1' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              zIndex: '1',
            }}
          >
            <StyledPaper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ padding: '0' }}
                >
                  {!translateView &&
                    TOOLBOX_ACTIONS.map((action, index) =>
                      'element' in action ? (
                        <Divider key={`${action.element}_${index}`} sx={{ my: '10px !important' }} />
                      ) : (
                        <StyledMenuItem
                          key={`${action.text}_${index}`}
                          disabled={!job}
                          onClick={() => onDownload(action.type)}
                        >
                          <span className="material-symbols-outlined">download</span>
                          <FormattedMessage id="audioToText.downloadAs" /> {action.text}
                        </StyledMenuItem>
                      ),
                    )}
                  {/* <StyledMenuItem onClick={handleClose}>
                    <span className="material-symbols-outlined">edit</span>
                    Edit title
                  </StyledMenuItem>
                  <StyledMenuItem onClick={handleClose}>
                    <span className="material-symbols-outlined">edit</span>
                    Edit speaker name
                  </StyledMenuItem> */}
                  <StyledMenuItem onClick={onDelete}>
                    <span className="material-symbols-outlined">delete</span>
                    <FormattedMessage id="audioToText.deleteTranscription" />
                  </StyledMenuItem>
                </MenuList>
              </ClickAwayListener>
            </StyledPaper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  > span {
    margin-right: 8px;
  }
  padding-left: 24px;
  padding-top: 6px;
  padding-bottom: 6px;

  &:hover {
    background: #def3fb;
  }
`

const StyledPaper = styled(Paper)`
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.13);
  min-width: 280px;
`
