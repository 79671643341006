export const SwitchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2713_17692" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2713_17692)">
        <path
          d="M7 21L2 16L7 11L8.425 12.4L5.825 15H21V17H5.825L8.425 19.6L7 21ZM17 13L15.575 11.6L18.175 9H3V7H18.175L15.575 4.4L17 3L22 8L17 13Z"
          fill="#364152"
        />
      </g>
    </svg>
  )
}
