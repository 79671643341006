import { memo, useMemo } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Drawer, useMediaQuery } from '@mui/material'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'

// project imports
import { MenuList } from 'layout/MainLayout/MenuList'
import { LogoSection } from 'layout/MainLayout/LogoSection'
import { MiniDrawerStyled } from './MiniDrawerStyled'

import { LAYOUT_CONST } from 'constant'
import { drawerWidth } from 'constants/common'

import { useApp } from 'hooks/useApp'
import { config } from 'config'

// ==============================|| SIDEBAR DRAWER ||============================== //

export const Sidebar = memo(() => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const { drawerOpen, toggleDrawer } = useApp()

  const { layout, drawerType } = config

  const logo = useMemo(
    () => (
      <Box sx={{ display: 'flex', p: 2 }}>
        <LogoSection />
      </Box>
    ),
    [],
  )

  const drawerContent = (
    <>
      <MenuList />
      {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen}
    </>
  )

  const drawerSX = {
    paddingLeft: drawerOpen ? '20px' : 0,
    paddingRight: drawerOpen ? '20px' : 0,
    marginTop: drawerOpen ? 0 : '20px',
  }

  const drawer = useMemo(
    () => (
      <>
        {matchDownMd ? (
          <Box sx={drawerSX}>{drawerContent}</Box>
        ) : (
          <PerfectScrollbar
            component="div"
            style={{
              height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
              ...drawerSX,
            }}
          >
            {drawerContent}
          </PerfectScrollbar>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matchUpMd, drawerOpen, drawerType],
  )

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
      aria-label="mailbox folders"
    >
      {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
        <Drawer
          variant={matchUpMd ? 'persistent' : 'temporary'}
          anchor="left"
          open={drawerOpen}
          onClose={() => toggleDrawer(!drawerOpen)}
          sx={{
            zIndex: 1000,
            visibility: drawerOpen ? 'visible' : 'hidden',
            '& .MuiDrawer-paper': {
              mt: matchDownMd ? 0 : 11,
              zIndex: 1099,
              width: drawerWidth,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
              borderRight: 'none',
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {matchDownMd && logo}
          {drawer}
        </Drawer>
      ) : (
        <MiniDrawerStyled variant="permanent" open={drawerOpen}>
          {logo}
          {drawer}
        </MiniDrawerStyled>
      )}
    </Box>
  )
})

Sidebar.displayName = 'Sidebar'
